import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enJSON from "./en.json";
import frenchJSON from "./french.json";
import hindiJSON from "./hindi.json";
import persianJSON from "./persian.json";
import mandarinJSON from "./mandarin.json";
import arabicJSON from "./arabic.json";
import vietnameseJSON from "./vietnamese.json";
import cantoneseJSON from "./cantonese.json";
import spanishJSON from "./spanish.json";
import punjabiJSON from "./punjabi.json";
import greekJSON from "./greek.json";
import italianJSON from "./italian.json";
import nepaliJSON from "./nepali.json";
import koreanJSON from "./korean.json";
import urduJSON from "./urdu.json";
import tamilJSON from "./tamil.json";
import filipinoJSON from "./filipino.json";
import sinhalaJSON from "./sinhala.json";
import gujaratiJSON from "./gujarati.json";
import malayalamJSON from "./malayalam.json";
import indonesianJSON from "./indonesian.json";
import germanJSON from "./german.json";
import bengaliJSON from "./bengali.json";
import portugueseiJSON from "./portuguese.json";
import mongolianJSON from "./mongolian.json";
import japaneseJSON from "./japanese.json";

i18n.use(initReactI18next).init({
    resources: {
        en: {...enJSON},
        fr: {...frenchJSON},
        hi: {...hindiJSON},
        fa: {...persianJSON},
        zh: {...mandarinJSON},
        ar: {...arabicJSON},
        vi: {...vietnameseJSON},
        yue: {...cantoneseJSON},
        es: {...spanishJSON},
        pa: {...punjabiJSON},
        el: {...greekJSON},
        it: {...italianJSON},
        ne: {...nepaliJSON},
        ko: {...koreanJSON},
        ur: {...urduJSON},
        ta: {...tamilJSON},
        fil: {...filipinoJSON},
        si: {...sinhalaJSON},
        gu: {...gujaratiJSON},
        ml: {...malayalamJSON},
        id: {...indonesianJSON},
        de: {...germanJSON},
        bn: {...bengaliJSON},
        pt: {...portugueseiJSON},
        mn: {...mongolianJSON},
        ja: {...japaneseJSON}
    },
    lng: "en",
    keySeparator: '.', // "." or false
        interpolation: {
            escapeValue: false
        }
}).catch((error) => {
    console.error(error?.stack)
})

export default i18n