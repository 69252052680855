import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
  downloadApp: {
    border: `1px solid ${colors.primaryLightGrey}`,
    background: colors.tertiary,
    borderRadius: "8px",
    width: "402px",
    height: "138px",
    padding: "24px 16px",
    display: "inline-flex",
    justifyContent: "space-between",
    marginTop: "32px",
    marginBottom: "32px",
    position: "relative",
    zIndex: 1,
    "& *":{
      fontFamily: "Noto-sans !important"
    }
  },
  rtlDownloadAppSection1:{
    marginRight: "0px",
    marginLeft: "64px",
  },
  heading:{
    fontSize: "24px",
    fontWeight: "bold",
    lineHeight: 1.33
  },
  heading2: {
    fontSize: "28px",
    fontWeight: "bold",
    lineHeight: "33.6px",
  },
  heading3: {
    fontSize: "14px",
    marginBottom: "24px",
  },
  heading4:{
    fontSize: "12px",
    fontWeight: "bold",
    marginBottom: "12px",
    textTransform: "uppercase",
    lineHeight: "normal"
  },
  heading5:{
    fontSize: "14px",
    marginTop: "8px",
    marginBottom: "20px",
    fontWeight: 500
  },
  storeLinks: {
    display: "inline-flex",
    "& img:first-child": {
      marginRight: "8px",
    },
  },
  rtlStoreLinkes:{
    "& img:first-child": {
      marginLeft: "8px",
      marginRight: "0px",
    },
  },
  downloadAppMobile: {
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    marginTop: "32px",
    padding: "24px 8px 16px 8px",
    marginBottom: "24px",
    border: `1px solid ${colors.primary}`,
    background: colors.primaryLight,
    borderRadius: "8px",
    "& img": {
      height: "64px",
      width: "218.7px",
      "@media (max-width:280px)": {
        width: "calc(80vw - 32px)",
        height: "8vh"
      }
    },

  },
  space:{
    height: "24px"
  }
}));

export default useStyles;
