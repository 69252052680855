import { makeStyles } from "@material-ui/styles";
import { colors } from "../../../utils/constant";

const useStyles = makeStyles(() => ({
  successScreenRoot: {
    margin: "24px auto",
    maxWidth: "436px",
    position: "relative",
    "& *": {
      textAlign: "start",
      "@media (max-width:599px)": {
        textAlign: "center",
      }
    },
    "@media (max-width:500px)": {
      width: "calc(100vw - 40px)",
    },
    "@media (max-width:599px)": {
      position: "initial !important",
    },
  },
  desktopSuccessScreenRoot: {
    padding: "32px 84px 0px 116px",
    position: "relative",
    overflowY: "auto",
    "& *": {
      textAlign: "start",
    },
  },
  heading: {
    fontSize: "24px",
    fontWeight: "bold"
  },
  description: {
    fontSize: "16px",
    lineHeight: 1.75,
    marginTop: "24px",
    "& span": {
      fontWeight: "bold",
    },
    "&:nth-child(3)": {
      marginBottom: "0px !important",
    },
    "& a":{
      zIndex: 1,
      position: "sticky"
    }
  },
  aboutIcon: {
    height: "24px",
    width: "24px",
    marginBottom: "8px",
    display: "block",
    "@media (max-width:599px)": {
      margin: "0 auto 8px auto"
    }
  },
  instructions: {
    fontSize: "12px",
    lineHeight: 1.67,
    maxWidth: "90vw",
    "& a":{
      textDecoration: "none",
      color: colors.dark,
      zIndex: 1,
      position: "sticky"
    }
  }
}));

export default useStyles;
