import { useSelector } from "react-redux";
import { useTranslation, Trans } from "react-i18next";
import SuccessScreen from "../SuccessScreen";

const ExistingMember = ({ name }) => {
  const { t } = useTranslation();
  const schoolData = useSelector((state) => state?.childDetails?.schoolData);
  return (
    <SuccessScreen
      heading={t("Hi firstName!", { firstName: name })}
      description1={
        <span>{t("It seems you already have an account with us.")}</span>
      }
      instruction={
        <Trans
          i18nKey="For assistance, email supportEmail."
          values={{ supportEmail: schoolData?.formData?.email }}
          components={{
            a: (
              <a
                target="_blank"
                href={`mailto:${schoolData?.formData?.email}`}
                rel="noreferrer"
              >
                {schoolData?.formData?.email}
              </a>
            ),
          }}
        />
      }
    />
  );
};

export default ExistingMember;
